"use client";
import {
  Account,
  Session,
  accountAtom,
  sessionAtom,
} from "@zapier/canvas-state";
import { Provider, createStore } from "jotai";
import { useHydrateAtoms } from "jotai/utils";

const store = createStore();

type Props = {
  session: Session;
  account: Account;
  children: React.ReactNode;
};

export function CanvasListLayout({ session, account, children }: Props) {
  return (
    <Provider store={store}>
      <CanvasListLayoutInner session={session} account={account}>
        {children}
      </CanvasListLayoutInner>
    </Provider>
  );
}

function CanvasListLayoutInner({ session, account, children }: Props) {
  useHydrateAtoms([
    [sessionAtom, session],
    [accountAtom, account],
  ]);
  return children;
}
